.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

@include media( '>medium' ) {

	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}

form#newsletter{
  display:grid;
  grid-template-columns: 75% 25%;
}
form#newsletter button > svg {
  position: relative;
  top: 6px;
  left: 12px;
}
.newsletter_input{
  background-color: #cb8b01;
}
#newsletter_submit{
  background-color: #cb8b01;
  border-color: transparent;
}
